import front from './assets/front.png';
import end from './assets/end.jpg';
import voEnd from './assets/voiu/end.png';
import voFront from './assets/voiu/front.png';
import h15Front from './assets/15h/front.png';
import './App.css';
import AddressView from './AddressView';

function App() {
  const isMobile = window.innerWidth < 600
  const pathName = window.location.pathname.toLowerCase()

  const is15h = pathName.includes('15h')
  const isWife = pathName.includes('HoaiThu'.toLowerCase())

  const imageFront = isWife ? voFront : is15h ? h15Front : front
  const imageEnd = isWife ? voEnd : end

  const urlParty = 'https://maps.app.goo.gl/e6sZtzNJ2L2UtqmNA'
  const urlWife = 'https://maps.app.goo.gl/WfEU7d6wKzFk2a5A9'

  const frameParty = isWife ? 'https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d927.835547262916!2d105.2200452696223!3d21.533471246265638!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjHCsDMyJzAwLjUiTiAxMDXCsDEzJzE0LjUiRQ!5e0!3m2!1sen!2s!4v1724602590060!5m2!1sen!2s' : 'https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d927.7739370834122!2d105.1803056!3d21.543111099999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjHCsDMyJzM1LjIiTiAxMDXCsDEwJzQ5LjEiRQ!5e0!3m2!1svi!2s!4v1724584273656!5m2!1svi!2s'
  const frameHome = isWife ? 'https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d927.835547262916!2d105.2200452696223!3d21.533471246265638!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjHCsDMyJzAwLjUiTiAxMDXCsDEzJzE0LjUiRQ!5e0!3m2!1sen!2s!4v1724602590060!5m2!1sen!2s' : 'https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d927.780513940089!2d105.17370826962237!3d21.542082246191214!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjHCsDMyJzMxLjUiTiAxMDXCsDEwJzI3LjciRQ!5e0!3m2!1sen!2s!4v1724603263457!5m2!1sen!2s'

  const qr = isWife ? 'https://img.vietqr.io/image/970423-05469208301-qr_only.png?addInfo=CHUC%20MUNG%20HANH%20PHUC%20TRAM%20NAM&accountName=NGUYEN%20HOAI%20THU' : 'https://img.vietqr.io/image/970423-05258762601-qr_only.png?addInfo=CHUC%20MUNG%20HANH%20PHUC%20TRAM%20NAM&accountName=DO%20MANH%20TAI'
  const stkText = isWife ? '05469208301 - TP Bank - NGUYEN HOAI THU' : '05258762601 - TP Bank - DO MANH TAI'

  return (
    <div style={{
      position: 'relative'
    }}>
      {
        !isMobile &&
        <div style={{
          position: 'fixed',
          zIndex: -1
        }}>
          <img src={'https://media.istockphoto.com/id/1393912497/vector/pink-watercolor-splash-background-with-line-art-rose.jpg?s=612x612&w=0&k=20&c=hl_I2tAWeoJKjTesDRzterx4h6EIDy8THPWeGoJzTjE='} alt="" style={{
            opacity: 0.3,
            objectFit: 'cover',
            width: '100vw',
            height: '100vh'
          }} />
        </div>
      }

      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        alignContent: 'center',
        paddingLeft: 16,
        paddingRight: 16,
      }}>
        <div style={{ marginTop: 12 }}></div>

        <a href={isWife ? urlWife : urlParty} target='_blank'>
          <img src={imageFront} className='img' />
        </a>
        <div style={{ marginTop: 16 }}></div>
        <a href={urlParty} target='_blank'>
          <img src={imageEnd} className='img' />
        </a>
        <div style={{ marginTop: 64 }}></div>

        {
          isWife
            ? <AddressView
              title='💒 Địa chỉ tổ chức tiệc:'
              content='Tư gia nhà gái, Khu 6, xã Chân Mộng, huyện Đoan Hùng, tỉnh Phú Thọ'
              link={urlWife} />
            : <AddressView
              title='💒 Địa chỉ tổ chức tiệc:'
              content='Nhà Hàng Ngọc Thoại, Khu 4, xã Minh Tiến, huyện Đoan Hùng, tỉnh Phú Thọ'
              link={urlParty} />
        }


        <div style={{ marginTop: 12 }}></div>
        <iframe
          src={frameParty}
          width="100%"
          height="450"
          loading="lazy"
          allowFullScreen={false}
          referrerPolicy='no-referrer-when-downgrade'
          style={{
            border: 'none'
          }}
        ></iframe>
        <div style={{ marginTop: 64 }}></div>
        {
          isWife
            ? <AddressView
              title='Địa chỉ nhà Gái: '
              content='Tư gia nhà gái, Khu 6, xã Chân Mộng, huyện Đoan Hùng, tỉnh Phú Thọ'
              link={urlWife} />
            : <AddressView
              title='Địa chỉ nhà Trai: '
              content='Đối diện UBND xã Minh Tiến, Khu 3, huyện Đoan Hùng, tỉnh Phú Thọ'
              link='https://maps.app.goo.gl/qZn2R8J8WMDk4KcF7' />
        }

        <div style={{ marginTop: 12 }}></div>
        {
          isWife
            ? <AddressView
              title='Hỗ trợ: '
              content='0378.041.531 - Nguyễn Hoài Thu'
              link='tel:0378041531' />
            : <AddressView
              title='Hỗ trợ: '
              content='0943.574.556 - Đỗ Mạnh Tài'
              link='tel:0943574556' />
        }
        <div style={{ marginTop: 12 }}></div>
        <iframe
          src={frameHome}
          width="100%"
          height="450"
          loading="lazy"
          allowFullScreen={false}
          referrerPolicy='no-referrer-when-downgrade'
          style={{
            border: 'none'
          }}
        ></iframe>
        <div style={{ marginTop: 32 }}></div>
        <h3>Mọi sự yêu thương từ xa xin gửi về</h3>
        <img
          src={qr}
          width={150}
          alt="" />
        <div style={{
          marginTop: 16,
          textAlign: 'center'
        }}>
          STK: <b>{stkText}</b>
        </div>

      </div>
      <div style={{
        marginTop: 32,
        paddingTop: 32,
        paddingBottom: 32,
        paddingLeft: 16,
        paddingRight: 16,
        textAlign: 'center',
        borderTop: '1px solid gainsboro'
      }}>
        Sự hiện diện của Quý Khách là vinh dự cho gia đình chúng tôi.
      </div>
    </div>

  );
}

export default App;
