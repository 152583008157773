
interface AddressViewType {
	title: string,
	content: string
	link: string,

}

const AddressView = (props: AddressViewType) => {
	return (
		<div>
			<b>
				{props.title} <a href={props.link} target="_blank"><i>{props.content}</i></a>
			</b>
		</div>
	);
};

export default AddressView;
